<template>
    <div class="input-group">
        <input class="form-control" type="text"
               v-model="secretValueOrMasked" ref="secretValueInput" readonly />

        <button class="btn btn-outline-secondary input-group-text" @click.prevent="showSecretValue = !showSecretValue">
            <template v-if="!showSecretValue">Show</template>
            <template v-if="showSecretValue">Hide</template>
        </button>
        <button v-if="showSecretValue" class="btn btn-outline-secondary input-group-text" @click.prevent="copyCode()">Copy</button>
    </div>
</template>

<script>

    import ScNotification from '../../shared/common/ScNotification.vue';

    export default {
        name: "ScMaskedSecretField",
        props: {
            secretValue: {type: String, default: null}
        },
        data () {
            return {
                showSecretValue: false
            };
        },
        mounted () {

        },
        methods: {
            copyCode () {
                this.$refs.secretValueInput.select();
                let successful = false;
                try {
                    successful = document.execCommand('copy');
                    console.log(successful)
                } catch (err) {
                    // ignore
                }
                if (successful)
                    ScNotification.growlSuccessMsg('Copied to your clipboard');
                else
                    ScNotification.growlErrMsg('Unable to copy');
            },


        },
        computed: {
            secretValueOrMasked() {
                if (this.showSecretValue) return this.secretValue;
                return "*********"
            }
        },

    }
</script>

