<template>
  <ScNormalPageLayout headingPart1="Subscription" headingPart2="Settings" submenu="settings">
    <div class="container my-5">
      <div class="row justify-content-center">

        <div v-if="isLoading">
          <div class="text-center" style="margin-top: 20vh; margin-bottom: 20vh;">
            <ScIcon name="spinnerMedium" class="text-muted"/>
          </div>
        </div>

        <div v-else-if="billingDetailsError">
          <div class="text-center" style="margin-top: 20vh; margin-bottom: 20vh;">
            <div class="alert alert-danger">{{billingDetailsError}}</div>
          </div>
        </div>

        <template v-else >
          <template v-if="isSubscription">
            <div class="card col-10 mb-3">
              <div class="card-body">
                <div class="lead">
                  <p v-if="isExpired">
                    Your subscription to the <strong>{{ planName }} Plan</strong> has expired.
                  </p>
                  <p v-else >
                    You are subscribed to the <strong>{{ planName }} Plan</strong>
                  </p>
                </div>
                <p v-if="daysLeft > 0">
                  This plan will expire in <strong>{{ daysLeft }} days</strong>
                </p>
                <p v-if="usedUsers > 0">
                  You have <strong>{{ usedUsers }}</strong> active {{ fmtCountWording(usedUsers, 'user') }}
                </p>
              </div>
            </div>

            <template v-if="chargifySubscription && !daysLeft">
              <div class="card col-10 mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <div v-if="chargifySubscription && adjustedDate">
                        <p>Your credit card will next be billed on
                          <strong>
                            {{ adjustedDate }}
                          </strong>
                          <template v-if="nextAmountBilled">
                            for <strong>${{ parseFloat(nextAmountBilled).toFixed(2) }}</strong>
                            <template v-if="billingCountry === 'NZ'"> +GST</template>
                          </template>
                        </p>
                      </div>

                      <div v-if="chargifySubscription.update_url">
                        <a class="btn btn-info" :href="chargifySubscription.update_url" >Update credit card details</a>
                      </div>
                      <div v-else>
                        To change payment details please contact us.
                      </div>
                    </div>
                    <div class="col-6 text-end">

                      <div v-if="showCancelConfirmation">
                        <div class="text-start">
                          Thanks, we're processing your request to cancel your credit card subscription
                          to Showcase. If there's anything we can help with in the meantime, let us know at
                          <a href="mailto:helpdesk@showcaseworkshop.com">helpdesk@showcaseworkshop.com</a>.
                        </div>
                      </div>
                      <div v-else class="">
                        <br/><br/><br/>
                        <button class="btn btn-link me-2" @click.prevent="cancelButton"
                                :disabled="showCancelSpinner">Cancel Subscription</button>
                        <span v-if="showCancelSpinner" class="align-middle">
                          <ScIcon name="spinnerFW" class="text-muted"/></span>
                        <p class="mb-2"><small></small></p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </template>
          </template>

          <div v-if="!isSubscription && !chargifyProduct && !isLoading" class="card col-10 mb-3">
            <div class="card-body">
              <div class="lead">
                Please contact us to subscribe.
              </div>
            </div>
          </div>

          <div v-if="chargifyProduct && chargifySubscription" class="card col-10 mb-3">
            <div class="card-body">
              <div class="lead">
                <h4 class="my-2">Pricing</h4>
                <div>
                  <p><strong>Accounts are charged monthly in USD:
                    <a href="https://showcaseworkshop.com/pricing/" target="_blank">view our full pricing schedule</a>.
                  </strong></p>
                  <div>GST (sales tax) will apply for billing addresses located in New Zealand.</div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!allowNewChargify && signupSourceName === 'isaac2015'" class="card col-10 mb-3">
            <div class="card-body">
              <div class="lead">
                Please contact Stuart Potter - <a href="mailto:stuart@isaacintel.com">stuart@isaacintel.com</a>
                for pricing information.
              </div>
            </div>
          </div>

          <div v-if="chargifyProduct && allowNewChargify" class="card col-10 mb-3">
            <div v-if="showManualBillingSuccess" class="card-body">
              <div class="lead">
                <h2 class="my-3">Thanks for Subscribing!</h2>
              </div>
              <p>Our sales team will contact either you or your billing contact soon.</p>

              <div class="text-center">
                <router-link :to="{ name: 'workshop-home', params: { workshopId: mainMxCurrentWorkshopId }}"
                             class="btn btn-outline-secondary">Back to Home</router-link>
              </div>
            </div>

            <div v-else class="card-body">
              <div class="lead">
                <h2 class="my-3">Upgrade</h2>
                <div>
                  <p><strong>Accounts are charged monthly in USD:
                    <a href="https://showcaseworkshop.com/pricing/" target="_blank">view our full pricing schedule</a>.
                  </strong></p>
                  <div>GST (sales tax) will apply for billing addresses located in New Zealand.</div>
                </div>
              </div>

              <div class="my-4">
                <div class="d-flex g-0">
                  <a :href="chargifyProduct.new_url"
                     :class="['btn', manualSelected ? '' : 'btn-primary-orange fw-bold']"
                     id="setup_cc_btn">Setup Credit Card</a>
                  <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#manual"
                          aria-expanded="false" aria-controls="manual">
                    Apply for Manual Billing
                  </button>
                </div>
                <div id="billing-tab-content" class="row justify-content-center mt-4">
                  <div class="col-10 active" id="credit">
                    <p>You can update your credit card details or cancel your subscription at any time by returning to this page.</p>
                  </div>
                  <div class="collapse col-10 fade" id="manual">
                    <template v-if="usedUsers < 20">
                      <div class="alert alert-info text-center">Showcase prefers credit card for accounts with less than 20 active users</div>
                    </template>

                    <p>Applications are subject to approval.</p>

                    <form class="manual-signup" @submit.prevent="">
                      <fieldset class="my-4">
                        <legend class="my-3 border-bottom">General</legend>
                        <div class="row mb-3">
                          <label for="name" class="col-5">Your name <span class="required">*</span></label>
                          <div class="col-7">
                            <input type="text" class="form-control" required="required"
                                   name="name" id="name" v-model.trim="name" />
                            <div v-if="!validForm && name === ''" class="alert alert-danger my-1">Please fill this in</div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label for="phone-number" class="col-5">Your phone number <span class="required">*</span></label>
                          <div class="col-7">
                            <input type="text" class="form-control" required="required"
                                   name="phone-number" id="phone-number"
                                   v-model.trim="phoneNumber" />
                            <div v-if="!validForm && phoneNumber === ''" class="alert alert-danger my-1">Please fill this in</div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label for="job-title" class="col-5">Your job title</label>
                          <div class="col-7">
                            <input type="text" class="form-control" name="job-title"
                                   id="job-title" v-model.trim="jobTitle" />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label for="company-name" class="col-5">Company Name <span class="required">*</span>
                            <span> (this is the name you want shown on the invoice)</span>
                          </label>
                          <div class="col-7">
                            <input type="text" class="form-control" required="required"
                                   name="company-name" id="company-name"
                                   v-model.trim="companyName" />
                            <div v-if="!validForm && companyName === ''" class="alert alert-danger my-1">Please fill this in</div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label for="postal-address" class="col-5">Postal address <span class="required">*</span></label>
                          <div class="col-7">
                            <textarea class="form-control" required="required"
                                      name="postal-address" id="postal-address"
                                      v-model.trim="postalAddress"></textarea>
                            <div v-if="!validForm && postalAddress === ''" class="alert alert-danger my-1">Please fill this in</div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label for="countries" class="col-5">Country <span class="required">*</span></label>
                          <div class="col-7">
                            <div class="customSelectArrow">
                              <select id="countries" class="form-control bg-white" name="country"
                                      required="required" v-model.trim="country">
                                <option value="" selected="selected"></option>
                                <optgroup label="Australia ($AUD) & NZ ($NZD)">
                                  <option value="New Zealand">New Zealand</option>
                                  <option value="Australia">Australia</option>
                                </optgroup>
                                <optgroup label="All other countries billed in $USD">
                                  <option v-for="(country, idx) in countriesList" :value="country" :key="'country-' + idx">{{ country }}</option>
                                </optgroup>
                              </select>
                            </div>
                            <div v-if="!validForm && country === ''" class="alert alert-danger my-1">Please fill this in</div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset class="my-4">
                        <legend class="my-3 border-bottom">Accounts Payable <span class="small">(if different from above)</span></legend>
                        <div class="row mb-3">
                          <label for="accounts-payable-contact-person" class="col-5">Accounts payable contact person</label>
                          <div class="col-7">
                            <input type="text" class="form-control"
                                   name="accounts-payable-contact-person"
                                   id="accounts-payable-contact-person"
                                   v-model.trim="accountsPayableContactPerson">
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label for="accounts-payable-telephone" class="col-5">Accounts payable telephone</label>
                          <div class="col-7">
                            <input type="text" class="form-control"
                                   name="accounts-payable-telephone"
                                   id="accounts-payable-telephone"
                                   v-model.trim="accountsPayableTelephone"/>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label for="accounts-payable-email" class="col-5">Accounts payable email</label>
                          <div class="col-7">
                            <input type="text" class="form-control"
                                   name="accounts-payable-email"
                                   id="accounts-payable-email"
                                   v-model.trim="accountsPayableEmail"/>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset class="my-4">
                        <legend class="my-3 border-bottom">Confirmation</legend>
                        <div class="col-7 offset-5">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="agree-confirm"
                                   id="agree-confirm" v-model="agreeConfirm" required="required">
                            <label class="form-check-label" for="agree-confirm">I confirm that
                              all of the information supplied on this form is correct and agree
                              to Showcase Workshop performing checks on the information provided.</label>
                          </div>
                          <div v-if="!validForm && agreeConfirm === null" class="alert alert-danger my-1">Please tick this if you agree</div>
                        </div>
                        <div class="col-7 offset-5">
                          <br/>
                          <button @click.prevent="submitForm" class="btn btn-primary-orange fw-bold" :disabled="showManualBillingSpinner">Request Manual Billing</button>
                          <span v-if="showManualBillingSpinner" class="d-inline-block align-middle"><ScIcon name="spinnerFW" class="mx-3 text-muted" /></span>
                          <div v-if="!validForm" class="alert alert-danger my-1">See above errors</div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </template>
      </div>
    </div>
  </ScNormalPageLayout>
</template>

<script>
    import $ from 'jquery';
    import ScNormalPageLayout from '../global/ScNormalPageLayout.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import MainAppMixin from '../MainAppMixin';
    import ScConfirmModal2 from '../../shared/common/ScConfirmModal2.vue';

    let DataManager = function () {
      return {
        loadBillingDefaults (vm) {
          vm.isLoading = true;
          $.ajax({
            type: 'POST',
            url: '/main/billing/ajax_billing_details',
            data: {
              workspace_id: vm.mainMxCurrentWorkshopId,

            }
          }).done((data) => {
            //console.log(data);
            if (data.status === 'ok') {
              vm.usedUsers = data.used_users;
              vm.daysLeft = data.days_left;
              vm.signupSourceName = data.signup_source_name;
              vm.chargifySubscription = data.chargify_subscription;
              vm.chargifyProduct = data.chargify_product;
              vm.allowNewChargify = data.allow_new_chargify;
              vm.billingCountry = data.billing_country;
              vm.nextAmountBilled = data.next_amount_billed;
              vm.isExpired = data.expired;
              vm.isSubscription = data.subscription;
              vm.planName = data.pricing_plan_name;
              vm.adjustedDate = data.adjusted_date;
            }   else {
              vm.billingDetailsError = data.message;
            }

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, "fetching billing information.");

          }).always(() => {
            vm.isLoading = false;
          });
        },
        submitBillingForm (vm) {
          vm.showManualBillingSpinner = true;
          $.ajax({
            type: 'POST',
            url: '/main/billing/ajax_manual_signup',
            data: {
              workspace_id: vm.mainMxCurrentWorkshopId,
              name: vm.name,
              phone_number: vm.phoneNumber,
              job_title: vm.jobTitle,
              company_name: vm.companyName,
              postal_address: vm.postalAddress,
              country: vm.country,
              accounts_payable_contact_person: vm.accountsPayableContactPerson,
              accounts_payable_telephone: vm.accountsPayableTelephone,
              accounts_payable_email: vm.accountsPayableEmail,
            }
          }).done((data) => {
            if (data.status && data.status === 'ok') {
              vm.showManualBillingSuccess = true;
              vm.$store.dispatch('userPrefsLoad');
            }

          }).fail((jqXhr) => {
            vm.showManualBillingSpinner = false;
            ScNotification.growlXhrError(jqXhr, "submitting form");

          });
        },
        cancelBilling (vm) {
          $.ajax({
            type: 'POST',
            url: '/main/billing/ajax_cancel_billing',
            data: {
              workspace_id: vm.mainMxCurrentWorkshopId
            }
          }).done((data) => {
            if (data.status && data.status === 'ok')
              vm.showCancelConfirmation = true;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, "canceling");
          });
        },


      }
    };

    let _dataManager = null;

    export default {
      name: "SettingsBilling",
      mixins: [ MainAppMixin ],
      components: { ScNormalPageLayout, ScIcon },
      data () {
        return {
          isLoading: false,
          billingDetailsError: null,

          isSubscription: null,
          isExpired: null,
          planName: null,
          daysLeft: null,
          usedUsers: null,
          signupSourceName: null,
          chargifySubscription: null,
          chargifyProduct: null,
          allowNewChargify: null,
          billingCountry: null,
          nextAmountBilled: null,
          adjustedDate: null,

          manualSelected: false,
          currentPeriodEndsAtDate: null,
          countriesList: ["Afghanistan", "Aland Islands", "Albania", "Algeria", "American Samoa", "Andorra", "Angola",
            "Anguilla", "Antarctica", "Antigua And Barbuda", "Argentina", "Armenia", "Aruba", "Austria", "Azerbaijan",
            "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan",
            "Bolivia, Plurinational State of", "Bonaire, Sint Eustatius and Saba", "Bosnia and Herzegovina", "Botswana",
            "Bouvet Island", "Brazil", "British Indian Ocean Territory", "Brunei Darussalam", "Bulgaria", "Burkina Faso",
            "Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde", "Cayman Islands", "Central African Republic", "Chad",
            "Chile", "China", "Christmas Island", "Cocos (Keeling) Islands", "Colombia", "Comoros", "Congo",
            "Congo, the Democratic Republic of the", "Cook Islands", "Costa Rica", "Cote d'Ivoire", "Croatia", "Cuba",
            "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt",
            "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia", "Falkland Islands (Malvinas)",
            "Faroe Islands", "Fiji", "Finland", "France", "French Guiana", "French Polynesia", "French Southern Territories",
            "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Gibraltar", "Greece", "Greenland", "Grenada", "Guadeloupe",
            "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea-Bissau", "Guyana", "Haiti",
            "Heard Island and McDonald Islands", "Holy See (Vatican City State)", "Honduras", "Hong Kong", "Hungary",
            "Iceland", "India", "Indonesia", "Iran, Islamic Republic of", "Iraq", "Ireland", "Isle of Man", "Israel",
            "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya", "Kiribati",
            "Korea, Democratic People's Republic of", "Korea, Republic of", "Kuwait", "Kyrgyzstan",
            "Lao People's Democratic Republic", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein",
            "Lithuania", "Luxembourg", "Macao", "Macedonia, The Former Yugoslav Republic Of", "Madagascar", "Malawi",
            "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Martinique", "Mauritania", "Mauritius", "Mayotte",
            "Mexico", "Micronesia, Federated States of", "Moldova, Republic of", "Monaco", "Mongolia", "Montenegro",
            "Montserrat", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Caledonia",
            "Nicaragua", "Niger", "Nigeria", "Niue", "Norfolk Island", "Northern Mariana Islands", "Norway", "Oman",
            "Pakistan", "Palau", "Palestinian Territory, Occupied", "Panama", "Papua New Guinea", "Paraguay", "Peru",
            "Philippines", "Pitcairn", "Poland", "Portugal", "Puerto Rico", "Qatar", "Reunion", "Romania",
            "Russian Federation", "Rwanda", "Saint Barthelemy", "Saint Helena, Ascension and Tristan da Cunha",
            "Saint Kitts and Nevis", "Saint Lucia", "Saint Martin (French Part)", "Saint Pierre and Miquelon",
            "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal",
            "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Sint Maarten (Dutch Part)", "Slovakia", "Slovenia",
            "Solomon Islands", "Somalia", "South Africa", "South Georgia and the South Sandwich Islands", "South Sudan",
            "Spain", "Sri Lanka", "Sudan", "Suriname", "Svalbard and Jan Mayen", "Swaziland", "Sweden", "Switzerland",
            "Syrian Arab Republic", "Taiwan", "Tajikistan", "Tanzania, United Republic of", "Thailand", "Timor-Leste",
            "Togo", "Tokelau", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan",
            "Turks and Caicos Islands", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
            "United States", "United States Minor Outlying Islands", "Uruguay", "Uzbekistan", "Vanuatu",
            "Venezuela, Bolivarian Republic of", "Vietnam", "Virgin Islands, British", "Virgin Islands, U.S.",
            "Wallis and Futuna", "Western Sahara", "Yemen", "Zambia", "Zimbabwe"],

          // form fields
          validForm: true,
          name: '',
          phoneNumber: '',
          jobTitle: '',
          companyName: '',
          postalAddress: '',
          country: '',
          accountsPayableContactPerson: '',
          accountsPayableTelephone: '',
          accountsPayableEmail: '',
          agreeConfirm: null,

          showCancelConfirmation: false,
          showCancelSpinner: false,
          showManualBillingSpinner: false,
          showManualBillingSuccess: false,

        };
      },
      mounted () {
        _dataManager = new DataManager();
        _dataManager.loadBillingDefaults(this);
      },
      methods: {
        fmtCountWording (num, word) {
          return (num > 1) ? " " + word + "s" : " " + word;
        },
        selectManual () {
          this.manualSelected = true;
        },
        submitForm () {
          this.validForm = true;
          let requiredFields = [this.name, this.phoneNumber, this.companyName, this.postalAddress, this.country,
            this.agreeConfirm];

          for (let field of requiredFields) {
            if (!field || field.length === 0) this.validForm = false;
          }

          if ( this.validForm ) {
            _dataManager.submitBillingForm(this);
          }

        },
        cancelButton () {
          ScConfirmModal2.modal('If you cancel, your workshop will be marked expired at the end of the current billing period.' +
              ' Are you sure?').then(() => {
            ScConfirmModal2.modal('We\'ll be sorry to see you go. You can re-subscribe in future from this page.' +
                ' Please confirm one last time for us!').then(() => {
              this.showCancelSpinner = true;
              _dataManager.cancelBilling(this);
            }, () => {});
          }, () => {});
        }

      }
    }
</script>
