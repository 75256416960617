<template>
    <ScNormalPageLayout headingPart1="Workshop" headingPart2="Settings" submenu="settings">
        <div class="container my-5 sc-workshop-settings-container">
            <div class="row justify-content-center mb-5">
                <div class="col-10">
                    <h3 class="mb-3">Edit Workshop</h3>
                    <form id="new-workspace-form" @submit.prevent="">
                        <div v-if="wsNameError" class="col-9 offset-3 from-group">
                            <div class="alert alert-danger form-error">{{ wsNameError }}</div>
                        </div>
                        <div class="mb-3 row">
                            <label for="workspace-name" class="col-3 text-end my-auto">Workshop name</label>
                            <div class="col-9">
                                <input class="form-control" id="workspace-name" name="name" type="text"
                                       v-model="workspaceName" maxlength="100" :disabled="wsNameLoading" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2 offset-3">
                                <input type="submit" @click.prevent="saveWsName" :disabled="wsNameLoading"
                                       class="btn btn-outline-secondary" value="Save" />
                            </div>
                            <div class="col-7 my-auto text-end">
                                <small class="text-muted"><ScIcon name="questionCircle"/> This is usually your company or team name</small>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="row justify-content-center mb-5">
                <div class="col-10 border-top">
                    <h3 class="mt-4">Access</h3>
                    <div class="row">
                        <div class="col-3 pt-2">
                            <ScToggleOnOff
                                    :loading="permissionsLoading"
                                    :active="!!allowPushNotify"
                                    @sc-toggle="togglePushNotify"></ScToggleOnOff>
                        </div>
                        <div class="col-9">
                            <strong>Send push notifications</strong>
                            <div>
                                <small>When publishing a presentation, send push notifications to users who have downloaded it.
                                    <br/>
                                    Notifications send on all platforms, but for Showcase XE &amp; Showcase Mac, the app must be open first.
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-3 pt-2">
                            <ScToggleOnOff
                                    :loading="permissionsLoading"
                                    :active="permissionType === null"
                                    @sc-toggle="togglePermissionType"></ScToggleOnOff>
                        </div>

                        <div class="col-9">
                            <strong>Limit View Access for new presentations</strong>
                            <div>
                                <small>Newly created, imported or copied presentations will have the "Limit View Access"
                                    setting (under 'Manage Access') switched ON by default.

                                This means they will only be visible to Admins, Managers, Reporters and Editors, until the access
                                    settings are changed under 'Manage Access'.</small>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-3 pt-2">
                            <ScToggleOnOff
                                    :loading="permissionsLoading"
                                    :active="!!viewShowcaseOnlineEnabled"
                                    @sc-toggle="toggleShowcaseOnlineEnabled"></ScToggleOnOff>
                        </div>
                        <div class="col-9">
                            <strong>"Allow full presentations to be shared" allowed by default for new presentations</strong>
                            <div>
                                <small>When this is ON, all newly created presentations will have the
                                    "Allow full presentation to be shared" setting (under 'Manage Sharing') switched ON.</small>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-3 pt-2">
                            <ScToggleOnOff
                                    :loading="permissionsLoading"
                                    :active="!!filesShareableOnSlideEnabled"
                                    @sc-toggle="toggleFilesShareableOnSlideEnabled"></ScToggleOnOff>
                        </div>
                        <div class="col-9">
                            <strong>Document and video files added to any hotspot will be shareable on that slide by default</strong>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-3 pt-2">
                            <ScToggleOnOff
                                    :loading="permissionsLoading"
                                    :active="!!allowImportDocInApps"
                                    @sc-toggle="toggleAllowImportDocInApps"></ScToggleOnOff>
                        </div>
                        <div class="col-9">
                            <strong>Editor and Admin users can convert PDF or PowerPoint file into a presentation within the apps</strong>

                            <div><small>
                                Files have a 100MB size limit; links and animations will not be maintained; first 100 slides or pages only.
                            </small></div>
                        </div>
                    </div>


                        <div class="mb-3 row mt-4">
                            <label for="share-days" class="col-3 text-end my-auto">Shared Content Expiry</label>
                            <div class="col-9">
                                <div class="customSelectArrow" style="width: 7.5rem">
                                    <select class="form-control bg-white" name="share-days" id="share-days"
                                            :disabled="permissionsLoading ? true : false"
                                            :value="sharedExpiryDays ? sharedExpiryDays : 'Unlimited'"
                                            @change="sharedExpiryDaysChanged">
                                        <option value="7">7 days</option>
                                        <option value="14">14 days</option>
                                        <option value="30">30 days</option>
                                        <option value="60">60 days</option>
                                        <option value="90">90 days</option>
                                        <option value="180">180 days</option>
                                        <option :value="null">Unlimited</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col offset-3 mt-2">
                                <small>The number of days a prospect may look at shared files for. 30 days is the default.</small>
                            </div>
                        </div>

                </div>
            </div>

            <div class="row justify-content-center mb-5">
                <div class="col-10 border-top">
                    <h3 class="mt-4 mb-3">Data API (<a href="https://github.com/ShowcaseSoftwareLtd/showcase-workshop-apis" target="_blank">documentation</a>)</h3>
                    <form id="webhook-form" @submit.prevent="">
                        <div class="mb-3 row">
                            <label for="webhook-url" class="col-3 text-end my-auto">Webhook URL</label>
                            <div class="col-9">
                                <input class="form-control" id="webhook-url" type="text" v-model="webhookUrl" maxlength="128" />
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="offset-3 col-9" v-if="webhookUrlError">
                                 <div class="alert alert-danger form-error">{{ webhookUrlError }}</div>
                            </div>
                            <div class="offset-3 col-9">
                                <button class="btn btn-outline-secondary me-2" @click.prevent="saveWebhookUrl">Save</button>
                                <button class="btn btn-outline-secondary" @click.prevent="testWebhookUrl">Test URL</button>
                            </div>
                        </div>
                    </form>

                     <form id="dev-key-form" @submit.prevent="">
                        <div class="mb-3 row">
                            <label class="col-3 text-end my-auto">Developer Key</label>
                            <div class="col-9">
                                <ScMaskedSecretField :secretValue="devKey"></ScMaskedSecretField>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="offset-3 col-9" v-if="devKeyError">
                                <div class="alert alert-danger form-error">{{ devKeyError }}</div>
                            </div>
                            <div class="offset-3 col-9">
                                <button type="submit" class="btn btn-outline-secondary" @click.prevent="regenerateDevKey">Regenerate key</button>
                            </div>
                        </div>
                    </form>

                    <form id="form-data-submission-key-form" @submit.prevent="">
                        <div class="mb-3 row">
                            <label class="col-3 text-end my-auto">Form Data Submission Key</label>
                            <div class="col-9">
                                <ScMaskedSecretField :secretValue="formDataSubmissionKey"></ScMaskedSecretField>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="offset-3 col-9" v-if="formDataSubmissionKeyError">
                                <div class="alert alert-danger form-error">{{ formDataSubmissionKeyError }}</div>
                            </div>
                            <div class="offset-3 col-9">
                                <button type="submit" class="btn btn-outline-secondary" @click.prevent="regenerateFormDataSubmissionKey">Regenerate key</button>
                            </div>
                        </div>
                    </form>

                    <div class="mb-3 row">
                        <label class="col-3 text-end my-auto">Workshop UID</label>
                        <div class="col-9">
                            <ScMaskedSecretField :secretValue="workspaceUid"></ScMaskedSecretField>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row justify-content-center mb-5">
                <div class="col-10 border-top">
                    <h3 class="mt-4 mb-3">Partner Workshops</h3>

                    <form id="partner-key-form" @submit.prevent="">
                        <div class="mb-3 row">
                            <label class="col-3 text-end my-auto">Your Partner Workshop Key</label>
                            <div class="col-9">
                                <ScMaskedSecretField :secretValue="partnerWsKey"></ScMaskedSecretField>
                            </div>
                        </div>

                        <div class="mb-3 row">

                            <label class="col-3 text-end mt-2">Existing Partners</label>

                            <div class="col-9">

                                <div class="mb-3" v-if="loadedPartner.length > 0">
                                    <div class="row border-bottom">
                                        <div class="col my-2"><strong>Name</strong></div>
                                        <div class="col my-2"><strong>Added</strong></div>
                                        <div class="col-2 my-2"></div>
                                    </div>
                                    <div v-for="item in loadedPartner" :key="'partner' + item.partner_workspace_id" class="row border-top">
                                        <div class="col my-2">{{ item.name }}</div>
                                        <div class="col my-2">{{ mainMxScDateFmt(item.inserted_date) }}</div>
                                        <div class="col-2 my-2 text-end">
                                            <a href="#" @click.prevent="removePartnerWs(item)">remove</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group mb-2">
                                    <input class="form-control" v-model="newPartnerKey" type="text" maxlength="40"
                                           placeholder="Add Partner Workshop Key (eg, 1234567-1234-5678-1234-5678123)" />
                                    <button class="btn btn-outline-secondary input-group-text" @click.prevent="addPartnerWs">Add</button>
                                </div>

                                <small class="text-muted">
                                    <ScIcon name="questionCircle"/>
                                    Add a new partner by asking them for their Partner Workshop Key then paste it here.
                                </small>

                            </div>

                        </div>
                    </form>
                </div>
            </div>

            <div class="row justify-content-center mb-5">
                <div class="col-10 border-top">
                    <h3 class="mt-4 mb-3">Hotspot URL User JWT Secret</h3>

                    <form id="weburl-user-jwt-secret-form" @submit.prevent="">
                        <div class="mb-3 row">
                            <label class="col-3 text-end my-auto">Secret</label>
                            <div class="col-9">
                                <ScMaskedSecretField :secretValue="weburlUserJwtSecret"></ScMaskedSecretField>
                            </div>
                            <div class="offset-3 col-9">
                                <small class="text-muted"><ScIcon name="questionCircle"/>
                                    The secret used to encode the User Info JSON Web Token (JWT), which can be used
                                    in hotspot URLs.
                                    <a href="https://github.com/ShowcaseSoftwareLtd/showcase-workshop-apis/blob/master/README.md#hotspot-url-special-variables" target="_blank">See documentation here</a>.</small>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="offset-3 col-9" v-if="weburlUserJwtSecretError">
                                <div class="alert alert-danger form-error">{{ weburlUserJwtSecretError }}</div>
                            </div>
                            <div class="offset-3 col-9">
                                <button type="submit" class="btn btn-outline-secondary" @click.prevent="regenerateWeburlUserJwtSecret">Regenerate secret</button>
                            </div>
                        </div>
                    </form>


                </div>
            </div>


            <div class="row justify-content-center mb-5">
                <div class="col-10 border-top">
                    <h3 class="my-4">Delete Workshop</h3>
                    <div class="alert alert-info">If you wish to delete your workshop please
                        contact support at <a href="mailto:helpdesk@showcaseworkshop.com?subject=Delete+Workshop">helpdesk@showcaseworkshop.com</a>.
                    </div>
                </div>
            </div>

        </div>
    </ScNormalPageLayout>
</template>

<script>

    import $ from 'jquery';
    import _ from 'underscore'; // without
    import ScMaskedSecretField from './ScMaskedSecretField.vue';
    import ScNormalPageLayout from '../global/ScNormalPageLayout.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScToggleOnOff from '../../shared/common/ScToggleOnOff.vue';
    import ScConfirmModal2 from '../../shared/common/ScConfirmModal2.vue';
    import MainAppMixin from '../MainAppMixin';

    let DataManager = function() {
        return {
            loadDefault (vm) {
                vm.webhookUrl = null;
                vm.devKey = null;
                vm.formDataSubmissionKey = null;
                vm.weburlUserJwtSecret = null;
                vm.workspaceUid = null;
                $.ajax({
                    type: 'POST',
                    url: '/main/workspaces/ajax_ws_settings_default_v2',
                    data: { workspace_id: vm.mainMxCurrentWorkshopId }
                }).done(function (data) {
                    vm.webhookUrl = data.webhook_url;
                    vm.devKey = data.dev_key;
                    vm.formDataSubmissionKey = data.form_data_submission_key;
                    vm.weburlUserJwtSecret = data.weburl_user_jwt_secret;
                    vm.workspaceUid = data.workspace_uid;
                });
            },
            saveWorkshopName (vm) {
                vm.wsNameLoading = true;
                $.ajax({
                    method: 'POST',
                    url: '/main/workspaces/ajax_change_name',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        name: vm.workspaceName
                    }

                }).done(function(data) {
                    if (data.status === 'ok') {
                        ScNotification.growlSuccessMsg('Workshop name successfully edited. This page will be refreshed in 3 seconds.');
                        setTimeout(function(){ vm.$router.go(); } , 3000);
                    } else
                        vm.wsNameError = data.message;

                }).always(function() {
                    vm.wsNameLoading = false;

                }).fail(function(jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'saving workshop name');
                });
            },

            loadAccessSettingsData (vm) {
                vm.permissionsLoading = true;

                $.ajax({
                    method: 'GET',
                    url: '/main/workspaces/ajax_showcase_default_access',
                    data: {workspace_id: vm.mainMxCurrentWorkshopId},

                }).done(function(data) {
                    vm.permissionType = data.default_showcase_permission_type;
                    vm.viewShowcaseOnlineEnabled = data.default_view_showcase_online_enabled;
                    vm.sharedExpiryDays = data.default_shared_expiry_days;
                    vm.allowPushNotify = data.allow_push_notifications;
                    vm.allowImportDocInApps = data.can_import_doc_in_apps;
                    vm.filesShareableOnSlideEnabled = data.files_shareable_on_slide_by_default;

                }).always(function() {
                    vm.permissionsLoading = false;

                }).fail(function(jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'fetching access settings');
                });
            },
            saveAccessSettingsData (vm) {
                vm.permissionsLoading = true;
                $.ajax({
                    method: 'POST',
                    url: '/main/workspaces/ajax_change_showcase_default_access',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        allow_push_notifications: vm.allowPushNotify,
                        can_import_doc_in_apps: vm.allowImportDocInApps,
                        default_showcase_permission_type: vm.permissionType,
                        default_view_showcase_online_enabled: vm.viewShowcaseOnlineEnabled,
                        default_shared_expiry_days: vm.sharedExpiryDays,
                        files_shareable_on_slide_by_default: vm.filesShareableOnSlideEnabled
                    }
                }).done(function() {

                }).always(function() {
                    vm.permissionsLoading = false;

                }).fail(function(jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'saving access settings');
                });

            },

            loadPartnerWorkspaces (vm) {
                $.ajax({
                    method: 'GET',
                    url: '/main/partners/ajax_ws_partner_settings',
                    data: {workspace_id: vm.mainMxCurrentWorkshopId},

                }).done(function(data) {
                     vm.loadedPartner = data.partners;
                     vm.partnerWsKey = data.partner_ws_key;

                }).fail(function(jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'fetching partner workshops');
                });
            },
            addPartnerWorkspace (vm) {
                $.ajax({
                    method: 'POST',
                    url: '/main/partners/ajax_ws_partner_add',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        partner_key: vm.newPartnerKey
                    }
                }).done(function(data) {
                    vm.loadedPartner.push({
                        partner_workspace_id: data.partner_workspace_id,
                        inserted_date: data.inserted_date,
                        name: data.name
                    });
                    vm.newPartnerKey = null;

                }).fail(function(jqXhr) {
                    let res = jqXhr.responseText ? jqXhr.responseText :'';
                    let msg = jqXhr.status + ' error when adding partner';
                    if (res.indexOf('"message":') > -1)
                        msg = JSON.parse(res).message;
                    ScNotification.growlErrMsg(msg);
                });
            },
            deletePartnerWorkspace (vm, ws) {
                $.ajax({
                    method: 'POST',
                    url: '/main/partners/ajax_ws_partner_remove',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        partner_workspace_id: ws.partner_workspace_id
                    }

                }).done(function() {
                     vm.loadedPartner = _.without(vm.loadedPartner, ws);

                }).fail(function(jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'removing partner workshop');
                });

            },

            updateDevKey (vm) {
                $.ajax({
                    method: 'POST',
                    url: '/main/workspaces/ajax_change_dev_key',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                    }

                }).done(function(data) {
                    if (data.error === false) {
                        ScNotification.growlSuccessMsg('Developer key has been successfully changed');
                        vm.devKey = data.message;
                    }
                    else
                        vm.devKeyError = data.message;

                }).fail(function(jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'updating developer key');
                });
            },

          updateFormDataSubmissionKey(vm) {
                $.ajax({
                    method: 'POST', url: '/main/workspaces/ajax_change_form_data_submission_key',
                    data: { workspace_id: vm.mainMxCurrentWorkshopId }
                }).done(function(data) {
                    if (data.error === false) {
                      ScNotification.growlSuccessMsg('Form data submission key has been successfully changed');
                      vm.formDataSubmissionKey = data.message;
                    } else {
                      vm.formDataSubmissionKeyError = data.message;
                    }
                }).fail(function(jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'updating form data submission key');
                });
            },
            updateWebhookUrl (vm) {
                $.ajax({
                    method: 'POST',
                    url: '/main/workspaces/ajax_change_webhook',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        value: vm.webhookUrl
                    }

                }).done(function(data) {
                    if (data.error === false)
                        ScNotification.growlSuccessMsg(data.message);
                    else
                        vm.webhookUrlError = data.message;

                }).fail(function(jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'updating webhook url');
                });

            },
            testWebhookUrl (vm) {
                $.ajax({
                    method: 'POST',
                    url: '/main/workspaces/ajax_test_webhook',
                    data: {
                        workspace_id: vm.mainMxCurrentWorkshopId,
                        value: vm.webhookUrl
                    }

                }).done(function(data) {
                    if (data.error === false)
                        ScNotification.growlSuccessMsg(data.message);
                    else
                        vm.webhookUrlError = data.message;

                }).fail(function(jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'testing webhook url');
                });
            },
            updateWeburlUserJwtSecret (vm) {
                $.ajax({
                    method: 'POST',
                    url: '/main/workspaces/ajax_change_weburl_user_jwt_secret',
                    data: {workspace_id: vm.mainMxCurrentWorkshopId}
                }).done(function(data) {
                    if (data.error === false) {
                        ScNotification.growlSuccessMsg('Secret has been successfully changed');
                        vm.weburlUserJwtSecret = data.message;
                    } else {
                        vm.weburlUserJwtSecretError = data.message;
                    }

                }).fail(function(jqXhr) {
                    ScNotification.growlXhrError(jqXhr, 'updating JWT secret');
                });
            },
        }
    };
    let _dataManager = null;

    export default {
        name: "SettingsWorkshop",
        mixins: [MainAppMixin],
        components: { ScNormalPageLayout, ScToggleOnOff, ScMaskedSecretField, ScIcon },
        data () {
            return {
                workspaceName: null,
                workspaceUid: null,
                webhookUrl: null,
                webhookUrlError: null,
                devKey: null,
                devKeyError: null,
                formDataSubmissionKey: null,
                formDataSubmissionKeyError: null,
                partnerWsKey: null,
                weburlUserJwtSecret: null,
                weburlUserJwtSecretError: null,
                showWeburlUserJwtSecret: false,

                permissionsLoading: false,
                wsNameLoading: false,
                wsNameError: null,
                permissionType: 'group-read',
                allowPushNotify: false,
                allowImportDocInApps: false,
                viewShowcaseOnlineEnabled: false,
                filesShareableOnSlideEnabled: false,
                sharedExpiryDays: 30,
                loadedPartner: [],
                newPartnerKey: null
            };
        },
        mounted () {
           _dataManager = new DataManager();
           _dataManager.loadDefault(this);
           _dataManager.loadAccessSettingsData(this);
           _dataManager.loadPartnerWorkspaces(this);

           this.workspaceName = this.currentWorkspaceName;
        },
        methods: {
            saveWsName () {
                if (this.workspaceName === null)
                    this.workspaceName = this.currentWorkspaceName;
                _dataManager.saveWorkshopName(this);
            },
            togglePushNotify () {
                this.allowPushNotify = this.allowPushNotify !== true;
                _dataManager.saveAccessSettingsData(this);
            },
            togglePermissionType () {
                this.permissionType = this.permissionType === null ? 'group-read' : null;
                _dataManager.saveAccessSettingsData(this);
            },
            toggleShowcaseOnlineEnabled () {
                this.viewShowcaseOnlineEnabled = this.viewShowcaseOnlineEnabled !== true;
                _dataManager.saveAccessSettingsData(this);
            },
            toggleFilesShareableOnSlideEnabled () {
                this.filesShareableOnSlideEnabled = this.filesShareableOnSlideEnabled !== true;
                _dataManager.saveAccessSettingsData(this);
                //update in userWorskpaceObj
                this.$store.commit('userWorkspaceShareableOnSlideByDefault', this.filesShareableOnSlideEnabled);
            },
            toggleAllowImportDocInApps () {
                this.allowImportDocInApps = this.allowImportDocInApps !== true;
                _dataManager.saveAccessSettingsData(this);
            },
            removePartnerWs (ws) {
                ScConfirmModal2.modal('Are you sure, users may lose access to Presentations?').then(() => {
                    ScConfirmModal2.modal('This CANNOT be undone! Are you definitely sure?').then(() => {
                        _dataManager.deletePartnerWorkspace(this, ws);
                    }, () => {});
                }, () => {});
            },
            addPartnerWs () {
                if (this.newPartnerKey && /^[-0-9a-f]{36}$/.test(this.newPartnerKey))
                    _dataManager.addPartnerWorkspace(this);
                else
                    ScNotification.growlErrMsg('Partner Key is invalid');
            },
            regenerateDevKey () {
                ScConfirmModal2.modal('Are you sure, you will need to update your existing scripts?').then(() => {
                    _dataManager.updateDevKey(this);
                }, () => {});
            },
            regenerateFormDataSubmissionKey () {
                ScConfirmModal2.modal('Are you sure, you will need to update your existing forms?').then(() => {
                    _dataManager.updateFormDataSubmissionKey(this);
                }, () => {});
            },
            regenerateWeburlUserJwtSecret () {
                ScConfirmModal2.modal('Are you sure, this wil cause hotspot URLs with this variable not wot work until the user refreshes the home screen?').then(() => {
                    _dataManager.updateWeburlUserJwtSecret(this);
                }, () => {});
            },
            saveWebhookUrl () {
                _dataManager.updateWebhookUrl(this);
            },
            testWebhookUrl () {
                _dataManager.testWebhookUrl(this);
            },
            sharedExpiryDaysChanged(e) {
              console.log(e.target.value, '??')
              this.sharedExpiryDays = Number(e.target.value);
              _dataManager.saveAccessSettingsData(this);
            }


        },
        computed: {
            currentWorkspaceName() {
              if (this.$store.getters.userCurrentWorkspaceObj) return this.$store.getters.userCurrentWorkspaceObj.name;
              return null;
            }
        }
    }
</script>
