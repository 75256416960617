<template>
  <ScNormalPageLayout headingPart1="Email" headingPart2="Settings" submenu="settings">
    <div class="container my-5">

      <div class="row justify-content-center mb-5">
        <div class="col-10">
          <h3 class="my-4">Email & Sharing Header Image</h3>

          <div v-if="emailSettingsLoading" class="text-center">
            <ScIcon name="spinnerSmall" class="text-muted"/>
          </div>

          <template v-else >

            <div v-if="thumbUrl" class="row justify-content-center mt-4">
              <img :src="thumbUrl" style="width:500px;" class="me-2"/>
            </div>
            <div class="row justify-content-center mt-3">
              <div class="col-auto">
                <button v-if="headerImgId" @click.prevent="changeHeaderImg(true)"
                        :disabled="emailHeaderSaving" class="btn btn-outline-secondary">
                  Clear image
                </button>
                <button v-else @click.prevent="changeHeaderImg(false)" id="choose_image_btn"
                        :disabled="emailHeaderSaving" class="btn btn-outline-secondary">
                  Choose image
                </button>
              </div>
            </div>
            <div class="row justify-content-center mt-2">
              <div class="col-auto">
                <small class="text-muted"><ScIcon name="questionCircleFW"/> Image is used in Sharing,
                  Email Viewers, and User Invite emails. Perfect dimensions are 500px by 133px.</small>
              </div>
            </div>
            <div class="row justify-content-center mt-4 mb-2">
              <div class="col-auto">
                <button type="button" @click.prevent="showEmailPreview" class="btn btn-outline-secondary me-2"
                        :disabled="emailHeaderSaving || emailFooterSaving || emailPreviewLoading">Preview</button>
                <button type="button" @click.prevent="saveEmailHeader" class="btn btn-primary-orange fw-bold"
                        :disabled="emailHeaderSaving">Save</button>
              </div>
            </div>

          </template>
        </div>

      </div>

      <div class="row justify-content-center mb-5">
        <div class="col-10 border-top">
          <h3 class="mt-4">Email & Sharing Footer </h3>

          <div v-if="emailSettingsLoading" class="text-center">
            <ScIcon name="spinnerSmall" class="mt-4 text-muted"/>
          </div>
          <template v-else >

            <div class="row">
              <div class="col-12 text-end text-muted">
                <small>{{ disclaimerTypeCheck }}</small>
              </div>

              <div class="col-12">
                <textarea id="disclaimer" class="form-control" name="disclaimer"
                          placeholder="Type your footer or disclaimer (optional)..."
                          v-model="disclaimer"></textarea>

                <div class="text-muted text-end mt-2">
                  <small>
                    <ScIcon name="questionCircle" />
                    You can use basic HTML here, but included scripts or images may reduce
                    deliverability. Test thoroughly and use at your own risk!
                  </small>
                </div>
              </div>

            </div>
            <div class="row justify-content-center mt-4 mb-3">
              <div class="col-auto">
                <button type="button" @click.prevent="showEmailPreview" class="btn btn-outline-secondary me-2"
                        :disabled="emailFooterSaving || emailHeaderSaving || emailPreviewLoading">Preview</button>
                <button type="submit" @click.prevent="saveEmailFooter" class="btn btn-primary-orange fw-bold"
                        :disabled="emailFooterSaving">Save</button>
              </div>
            </div>

          </template>
        </div>
      </div>

      <teleport to=".sc-modals-anchor">
        <div v-if="showEmailPreviewModal" v-vb-is:modal.show @vb-hidden-bs-modal="showEmailPreviewModal=false"
             class="modal fade" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <button class="btn-close" data-bs-dismiss="modal"></button>
                <h4 class="modal-title w-100 text-center">Preview</h4>
              </div>
              <iframe id="iframe-preview" class="shadow-lg me-auto ms-auto w-100 border-0 rounded bg-white"
                      style="height: 70vh;"></iframe>
            </div>
          </div>
        </div>
      </teleport>

      <LibraryMainModal ref="libraryMainModal"></LibraryMainModal>

    </div>
  </ScNormalPageLayout>
</template>

<script>

    import $ from 'jquery';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScNormalPageLayout from '../global/ScNormalPageLayout.vue';
    import MainAppMixin from '../MainAppMixin';
    import LibraryMainModal from '../library/LibraryMainModal.vue';


    export default {
      name: "SettingsBranding",
      mixins: [ MainAppMixin ],
      components: {LibraryMainModal, ScNormalPageLayout, ScIcon },
      data () {
        return {
          emailHeaderSaving: false,
          emailFooterSaving: false,
          emailSettingsLoading: false,
          emailPreviewLoading: false,
          disclaimer: null,
          headerImgId: null,
          thumbUrl: null,
          showEmailPreviewModal: false
        };
      },
      mounted () {
        this.loadEmailSettings()
      },
      methods: {
        loadEmailSettings () {
          this.emailSettingsLoading = true;
          $.ajax({
            url: "/main/branding/ajax_get_email_settings",
            data: {workspace_id: this.mainMxCurrentWorkshopId}
          }).done((data) => {
            //console.log(data)
            this.disclaimer = data.disclaimer;
            this.headerImgId = data.header_img_id;
            this.thumbUrl = data.header_img_url;
          }).always(() => {
            this.emailSettingsLoading = false;
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, "fetching email settings");
          });
        },
        changeHeaderImg (clearImg) {
          if (clearImg){
            this.headerImgId = null;
            this.thumbUrl = null;
          }
          else {
            this.$refs.libraryMainModal.toggleBsModal({
              dialogType: 'modal', selectionMode: 'single',
              filterType: "image"
            }, (callback) => {
              //console.log('file(s) selected', callback);
              this.headerImgId = callback && callback.length > 0 && callback[0].id ? callback[0].id : null;
              this.thumbUrl = callback[0].url;
            });
          }
        },
        saveEmailHeader () {
          this.emailHeaderSaving = true;
          $.ajax({
            type: "POST", url: "/main/branding/ajax_set_email_header",
            data: { workspace_id: this.mainMxCurrentWorkshopId, header_img_id: this.headerImgId }
          }).done(() => {
            ScNotification.growlSuccessMsg('Header image saved');
            this.$store.commit('userUpdateHeaderImg', { header_url: this.thumbUrl });
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, "saving settings");
          }).always(() => {
            this.emailHeaderSaving = false;
          });
        },
        saveEmailFooter () {
          this.emailFooterSaving = true;
          $.ajax({
            type: "POST", url: "/main/branding/ajax_set_email_footer",
            data: { workspace_id: this.mainMxCurrentWorkshopId, disclaimer: this.disclaimer }
          }).done(() => {
            ScNotification.growlSuccessMsg('Footer saved');
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, "saving settings");
          }).always(() => {
            this.emailFooterSaving = false;
          });
        },
        showEmailPreview () {
          this.emailPreviewLoading = true;
          $.ajax({
            type: "POST", url: "/main/branding/email_preview",
            data: {
              workspace_id: this.mainMxCurrentWorkshopId,
              header_img_id: this.headerImgId,
              disclaimer: this.disclaimer
            }
          }).done((data) => {
            this.showEmailPreviewModal = true;
            setTimeout(() => {  // delay so that state is updated first
              $('#iframe-preview').contents().find('html').html(data);
            });
          }).always(() => {
            this.emailPreviewLoading = false;
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, "previewing email");
          });
        }
      },
      computed: {
        disclaimerTypeCheck () {
          return (this.disclaimer !== null && this.disclaimer.includes('<') && this.disclaimer.includes('>')) ?
              'Detected: HTML' : 'Detected: Plain Text'
        }
      }
    }
</script>