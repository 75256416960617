<template>
  <ScNormalPageLayout headingPart1="Subscription" headingPart2="Success" submenu="settings">

    <div class="container">
      <div class="row">
        <div class="col-6 offset-3 mt-5">
          <h2>Thanks for Subscribing!</h2>

          <p>If you need to update payment details in future, visit the Subscription Settings page again.</p>

          <p>There's also a link to manage your subscription details at the bottom of your invoice, called "Account Management".</p>

          <p>Note, our system may take up to 15 minutes to remove any expired messages or subscription reminders.</p>

          <br/>
          <div class="text-center">
            <router-link :to="{ name: 'workshop-home', params: { workshopId: mainMxCurrentWorkshopId }}"
                         class="btn btn-outline-secondary">Back to Home</router-link>
          </div>
        </div>
      </div>
    </div>

  </ScNormalPageLayout>
</template>

<script>

    import ScNormalPageLayout from '../global/ScNormalPageLayout.vue';
    import MainAppMixin from "../MainAppMixin";

    export default {
      name: 'SettingsBillingSuccess',
      mixins: [MainAppMixin],
      components: {ScNormalPageLayout},

      mounted() {
        this.$store.commit('userExtendSubscriptionAlert');
      }
    }

</script>